import './App.css';
import PortFolio from './pages/portfolio'
function App() {
  return (
    <div className="App">
      <PortFolio />
    </div>
  );
}

export default App;
